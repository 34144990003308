import React, { Fragment, useState } from 'react'
import { v4 as uuidv4 } from 'uuid'
import { sanitizeFormValue } from '../../utils'
import { cloneDeep } from 'lodash'

const botfield = uuidv4()
const defaultFormState = {
  [botfield]: '',
  'form-name': 'contact',
  name: '',
  email: '',
  message: '',
}

const encode = (data) => {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&')
}

const ContactPanel = () => {
  const [values, setValues] = useState(defaultFormState)
  const [msg, setMsg] = useState('')
  const [isWorking, setIsWorking] = useState(false)
  const [isSuccess, setIsSuccess] = useState(false)

  const btnClasses = [
    'submit',
    'button',
    isWorking ? 'is-loading' : '',
    isSuccess ? 'is-success' : '',
  ]
    .filter((item) => item)
    .join(' ')
  const btnText = isSuccess ? 'Success!' : 'Submit Your Message'

  function handleChange(e) {
    const { name, value } = e.target
    const newState = cloneDeep(values)
    newState[name] = sanitizeFormValue(value, name)
    setValues(newState)
  }

  async function handleSubmitAsync() {
    try {
      const result = await fetch('/', {
        method: 'POST',
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        body: encode(values),
      })
      const success = result && result.status && result.status === 200
      setIsWorking(false)
      setIsSuccess(success)
      setMsg('Thank you! Your message has been received.')
    } catch (e) {
      console.error(e)
      setIsWorking(false)
      setIsSuccess(false)
      setMsg(`Error: ${e.message}`)
    }
  }

  function handleSubmit(e) {
    e.preventDefault()
    setMsg('')
    if (values[botfield]) {
      return null
    }
    setIsWorking(true)
    handleSubmitAsync()
  }

  return (
    <Fragment>
      <form
        id="contact-form"
        name="contact"
        onSubmit={handleSubmit}
        method="post"
        data-netlify="true"
        data-netlify-honeypot={botfield}
      >
        <input type="hidden" name="form-name" value="contact" />
        <div hidden>
          <input
            name={botfield}
            type={'text'}
            aria-label={botfield}
            onChange={handleChange}
            tabIndex="-1"
          />
        </div>
        <input
          type="text"
          id="name"
          name="name"
          value={values.name}
          onChange={handleChange}
          placeholder="Name"
          aria-label="Name"
          required={true}
        />
        <input
          type="email"
          id="email"
          name="email"
          value={values.email}
          onChange={handleChange}
          placeholder="Email"
          aria-label="Email"
          required={true}
        />
        <textarea
          id="message"
          name="message"
          rows={10}
          required={true}
          onChange={handleChange}
          placeholder="Message"
          aria-label="Message"
        />
        <button
          className={btnClasses}
          type="submit"
          form="contact-form"
          disabled={!!isSuccess}
        >
          {btnText}
        </button>
      </form>
      {!!msg && (
        <div className={`notification ${isSuccess ? 'success' : 'error'}`}>
          {msg}
        </div>
      )}
    </Fragment>
  )
}

export default ContactPanel
